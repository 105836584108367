.Programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header {
    color: #fff;
    display: flex;
    font-size: 3rem;
    font-style: italic;
    font-weight: 700;
    gap: 5rem;
    justify-content: center;
    text-transform: uppercase;
}

.category, .program-categories {
    display: flex;
    gap: 1rem;
}

.category {
    background-color: gray;
    color: #fff;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
}

.category:hover{
    background: var(--planCard);
    cursor: pointer;
}

.category>:first-child {
    fill: #fff;
    height: 2rem;
    width: 2rem;
}

.category>:nth-child(2) {
    font-size: 1rem;
    font-weight: 700;
}

.category>:nth-child(3) {
    font-size: .9rem;
    line-height: 25px;
}

.join-now {
    align-items: center;
    display: flex;
    gap: 2rem;
}

.join-now>img {
    width: 1rem;
}

@media screen and (max-width: 768px) {
    .programs-header{
      flex-direction: column;
      gap: 1rem;
      font-size: x-large;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
    }
    .program-categories{
      flex-direction: column;
    }
  }