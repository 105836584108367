.hero {
    display: flex;
    justify-content:  space-between;
}

.hero-blur {
    height: 30rem;
    left: 0;
    width: 22rem;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;

}

.right-h{
    background-color: #f48916;
    flex: 1 1;
    position: relative;
}

.the-best-ad {
    margin-top: 4rem;
    background-color: #393d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.the-best-ad>span {
    z-index: 2;
}

.the-best-ad>div {
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero-text {
    display: flex;                  	/* Displays an element as a block-level flex container */
    flex-direction: column;             /* The flexible items are displayed vertically, as a column */
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures{
    display: flex;
    gap: 2rem;               /* the size of the gap between the rows and columns */
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.hero-button{
    display: flex;
    font-weight: 400!important;
    gap: 1rem;
}

.hero-button>button:nth-of-type(1){
    background-color: var(--orange);
    color: #fff;
    width: 8rem;
}

.hero-button>button:nth-of-type(2){
    background-color: transparent;
    color: #fff;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right-h>.btn {
    color: #000;
    position: absolute;
    right: 3rem;
    top: 2rem;
}

.heart-rate {
    align-items: start;
    background-color: var(--darkGrey);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    position: absolute;
    right: 4rem;
    top: 7rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.heart-rate>img {
    width: 2rem;
}

.heart-rate>span:nth-of-type(1){
    color: var(--gray);
}

.heart-rate>span:nth-of-type(2){
    color: #fff;
    font-size: 1.5rem;
}

.hero-image{
    position: absolute;
    right: 8rem;
    top: 10rem;
    width: 23rem;
}

.hero-image-back{
    position: absolute;
    right: 20rem;
    top: 4rem;
    width: 15rem;
    z-index: -1;
}

.calories {
    background-color: var(--caloryCard);
    border-radius: 5px;
    display: flex;
    gap: 2rem;
    padding: 1rem;
    position: absolute;
    right: 28rem;
    top: 32rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.calories>img {
    width: 3rem;
}

.calories>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories>div>:first-child {
    color: var(--gray);
}

.calories>div>:nth-child(2) {
    color: #fff;
    font-size: 1.5rem;
}

@media  screen and  (max-width: 768px ){ 
    .hero{
        flex-direction: column;
    }

    .heart-rate, .hero-image, .hero-image-back, .calories{
        visibility: hidden;
    }

     
    .hero-blur {
        width: 14rem;
    }

    .the-best-ad {
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .hero-text {
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text >:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .hero-buttons{
        justify-content: center;
       }

       .figures > div > span:nth-of-type(1) {
        font-size: large;
      }
      .figures > div > span:nth-of-type(2) {
        font-size: small;
      }
    
}